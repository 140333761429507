import { useTariffs } from '~/store/tariffs/tariffs.store'

export function recentSearchFn() {
  const tariffsStore = useTariffs()
  const recentSearch = computed(() => tariffsStore.getRecentSearch)

  const addRecentSearch = (address) => {
    const same = recentSearch.value.find(el => el.houseUrl === address.houseUrl)
    if (same) {
      recentSearch.value.splice(recentSearch.value.indexOf(same), 1)
    }
    recentSearch.value.unshift(address)

    nextTick(() => localStorage.setItem('recentSearch', JSON.stringify(recentSearch.value)))
  }

  const clearRecentSearch = () => {
    tariffsStore.$patch({
      recentSearch: [],
    })
    localStorage.setItem('recentSearch', '')
  }

  return {
    clearRecentSearch,
    addRecentSearch,
  }
}
